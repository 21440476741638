import React, { FC } from "react"
import { graphql, Link, PageProps } from "gatsby"

import Layout from "../components/layout"
import { RemarkQueryType } from "../types"

export const query = graphql`
  query ($slug: String!, $projectId: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }

    allMarkdownRemark(filter: { frontmatter: { project: { eq: $projectId } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

type QueryProps = {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
      }
    }
    allMarkdownRemark: RemarkQueryType<
      {
        title: string
      },
      {
        slug: string
      }
    >
  }
}

type Props = PageProps & QueryProps

const ProjectPage: FC<Props> = ({ data }) => {
  if (!data) {
    return <Layout>No data</Layout>
  }

  const project = data.markdownRemark
  const relatedArticles = data.allMarkdownRemark.edges

  if (!project) {
    return <Layout>Error :-(</Layout>
  }

  return (
    <Layout>
      <div>
        <h1>{project.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: project.html }} />
      </div>

      <div>
        <h3>Related Articles:</h3>
        <ul>
          {relatedArticles.map((edge) => (
            <li key={edge.node.id}>
              <Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default ProjectPage
